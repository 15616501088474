<template>
    <v-row class="mt-2">
        <v-col cols="12" class="d-flex justify-center text-center">
            <new-project-mobile-button
                v-if="mobile"
            ></new-project-mobile-button>
            <h1>{{ $t(`landing.heading`) }}</h1>
        </v-col>
        <transition name="close">
            <v-col v-if="!hero" cols="12" class="pa-0 hero_select__container">
                <select-hero
                    :is-mobile="mobile"
                    max-width="100%"
                    :selected-hero.sync="selectedHero"
                ></select-hero>

                <v-card
                    class="mx-auto"
                    max-width="480"
                    min-height="300"
                    color="appBackground"
                    flat
                >
                    <v-card-title>
                        <h3 class="word__wrap text-center">
                            {{
                                $t(`landing.selectedDescription`, {
                                    character: $t(
                                        `landing.characters.${selectedHero}`
                                    ),
                                })
                            }}
                        </h3>
                    </v-card-title>
                    <v-card-text
                        class="d-flex justify-center flex-column align-center"
                    >
                        <hero
                            animation-is="jump"
                            :size="200"
                            :explicit-hero="selectedHero"
                        ></hero>
                        <v-btn
                            text
                            depressed
                            color="primary lighten-4"
                            class="align-self-center mt-10 black--text"
                            @click="selectHero()"
                        >
                            {{ $t("general.choose") }}
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </transition>
        <v-col cols="12" class="my-12">
            <h2 class="py-8 word__wrap">
                {{ $t(`landing.projects.title`) }}
            </h2>
            <v-chip
                v-for="(item, idx) in categories"
                :key="idx"
                class="ma-1 ml-0 px-6"
                :class="
                    isExactRoute(item.name)
                        ? `${item.type} white--text`
                        : 'black--text'
                "
                medium
                @click="$router.replace({ name: item.name })"
            >
                {{ $t(`menu.links.${item.type}`) }}
            </v-chip>
            <router-view></router-view>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { getDevice } from "@/helpers/generalHelpers.js";
import { CONFIG } from "@/common/config.js";

import NewProjectMobileButton from "@/layouts/components/NewProjectMobileButton.vue";
import ProjectInfoCard from "@/components/InfoCard/ProjectInfoCard.vue";
import SelectHero from "@/components/General/SelectHero.vue";
import Hero from "@/components/Heroes/Hero.vue";
import ViewProjectBtn from "@/components/InfoCard/ViewProjectBtn.vue";

import { withAsync } from "@/helpers/withAsync.js";
import { getHeroes, setHero } from "@/api/chatbotApi.js";
export default {
    name: "Home",

    components: {
        NewProjectMobileButton,
        ProjectInfoCard,
        SelectHero,
        Hero,
        ViewProjectBtn,
    },

    data() {
        return {
            projects: [],
            cardHeight: CONFIG.min_card_heigth,
            mediaHeight: CONFIG.min_media_height,
            selectedHero: "tomato",

            categories: [
                { id: 1, name: "Garden", type: "garden" },
                { id: 2, name: "Constructions", type: "constructions" },
                { id: 3, name: "Sensors", type: "sensors" },
                { id: 4, name: "Nutrition", type: "nutrition" },
            ],

            mobile: false,
        };
    },

    computed: {
        ...mapGetters({
            isLoggedIn: "auth/isLoggedIn",
        }),

        ...mapState({
            hero: (state) => state.auth.hero,
            user_role: (state) => state.auth.user.role,
        }),
    },

    methods: {
        isExactRoute(item) {
            return item === this.$route.name;
        },
        async selectHero() {
            let url = `${this.user_role}/chatbot/update`;

            if (!this.isLoggedIn) {
                this.$store.dispatch("auth/setHero", this.selectedHero);
                return;
            }
            const payload = {
                chatbot_char: this.selectedHero,
            };
            const { response, error } = await withAsync(setHero, payload, url);

            if (error) {
                return;
            }

            this.$store.dispatch("auth/setHero", response.data.data.type);
        },
        isMobile() {
            this.mobile = getDevice();
        },
    },
    mounted() {
        window.addEventListener("resize", this.isMobile);
        this.isMobile();
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.isMobile);
    },
};
</script>

<style lang="scss" scoped>
.card-content {
    height: 200px;
}

.ps-relative {
    position: relative;
    padding: 8em 0;
}

.str-img {
    position: absolute;
    top: 0;
    z-index: 10;
    left: 10%;
    bottom: 49%;
}
.hero_select__container {
    max-height: 800px;
}

.close-enter-active,
.close-leave-active {
    transition: max-height 0.5s ease-in-out, transform 0.5s ease-in-out;
    transform-origin: top;
}

.close-enter-from,
.close-leave-to {
    max-height: 10px;
    transform: rotateX(90deg);
}
</style>
