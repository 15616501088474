<template>
    <v-row>
        <v-col>
            <v-row v-if="getConstructionsStatus_Success" class="mt-2">
                <v-col cols="12">
                    <h1>{{ $t(`Constructions.heading`) }}</h1>
                </v-col>
                <v-col
                    v-for="(project, index) in projects"
                    :key="index"
                    cols="12"
                    md="6"
                    lg="4"
                    xl="3"
                    class="mt-6"
                >
                    <project-info-card
                        :project="project"
                        type="project"
                        card-width="372"
                        :card-heigth="cardHeight"
                        :media-height="mediaHeight"
                        media-width="372"
                        :thumbnail="project.youtube_url ? true : false"
                        @card-click="viewProject(project)"
                    >
                        <template #viewAction>
                            <view-project-btn
                                @view-project="viewProject(project)"
                            ></view-project-btn>
                        </template>
                    </project-info-card>
                </v-col>
                <v-col v-if="projects.length <= 0" cols="12"></v-col>
            </v-row>
            <v-row>
                <v-col v-if="getConstructionsStatus_Error" cols="12">
                    {{ $t("general.noData") }}
                </v-col>
                <loading-spinner
                    :show="getConstructionsStatus_Pending"
                    color="constructions"
                ></loading-spinner>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { getAllProjects } from "@/api/projectsApi.js";
import { CONFIG } from "@/common/config.js";
import { apiStatus } from "@/api/constants/apiStatus.js";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus.js";

import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import ProjectInfoCard from "@/components/InfoCard/ProjectInfoCard.vue";
import ViewProjectBtn from "@/components/InfoCard/ViewProjectBtn.vue";
export default {
    name: "Constructions",

    components: {
        ProjectInfoCard,
        ViewProjectBtn,
        LoadingSpinner,
    },

    data() {
        return {
            projects: [],
            cardHeight: CONFIG.min_card_heigth,
            mediaHeight: CONFIG.min_media_height,
            getConstructionsStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["getConstructionsStatus"]),
        isInfoPage() {
            if (this.$route.params.id) {
                return true;
            }
            return false;
        },
    },

    methods: {
        async handleProjects() {
            this.getConstructionsStatus = apiStatus.Pending;
            const { response, error } = await withAsync(getAllProjects);

            if (error) {
                this.getConstructionsStatus = apiStatus.Error;
                return;
            }

            this.projects = response.data.data.filter(
                (item) =>
                    item.status == "publish" && item.category == "constructions"
            );
            this.getConstructionsStatus = apiStatus.Success;
        },
        viewProject(project) {
            this.$router.push({
                name: "ConstructionInfo",
                params: { id: project.id },
            });
        },
    },

    created() {
        this.handleProjects();
    },
};
</script>

<style lang="scss" scoped></style>
