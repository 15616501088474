<template>
    <v-row>
        <p class="mt-5 pt-3 pb-5">
            {{ $t(`sensorPage.info`) }}
            <br />
            <br />
            <v-spacer></v-spacer>
            {{ $t(`sensorPage.subInfo`) }}
        </p>
        <v-col
            lg="6"
            md="6"
            cols="12"
        >
            <v-card class="rounded-xl px-3">
                <v-card-title 
                    class="d-flex flex-lg-row flex-column"
                    @click="card1 = !card1">
                    {{ $t(`project.sensors.defaultSensor`) }}  
                    <b class="pl-1 text-break">
                        {{ $t(`project.sensors.defaultSensorName`) }}
                    </b>
                </v-card-title>
                <v-card-actions v-if="card1">
                    <v-col
                        lg="6"
                        md="3"
                        cols="6"
                    >
                        <p>
                        <b>{{ $t(`sensorPage.instructions`) }}</b>
                            <br />
                            <br />
                            {{ $t(`sensorPage.instructionsInfo`) }}
                        </p>
                    </v-col>
                    <v-col
                        lg="6"
                        md="3"
                        cols="6"
                        class="d-flex justify-end"
                    >
                        <v-btn
                            color="primary"
                            href="https://api.littlegreenfarmers.com//storage/instructions.pdf"
                            target="_blank"
                        >
                            {{ $t(`sensorPage.download`) }}
                        </v-btn>
                    </v-col>
                </v-card-actions>
                <v-card-actions v-if="card1">
                    <v-col
                        lg="6"
                        md="3"
                        cols="6"
                    >
                        <p>
                            <b>{{ $t(`sensorPage.software`) }}</b>
                            <br />
                            <br />
                            {{ $t(`sensorPage.softwareInstructions`) }}
                        </p>
                    </v-col>
                    <v-col
                        lg="6"
                        md="3"
                        cols="6"
                        class="d-flex justify-end"
                    >
                    <v-btn
                        color="primary"
                        href="https://api.littlegreenfarmers.com//storage/Capacitive-Soil-Moisture.zip"   
                    >
                        {{ $t(`sensorPage.download`) }}
                    </v-btn>
                    </v-col>
                </v-card-actions>
                <v-card-actions v-if="card1">
                <v-col
                    lg="6"
                    md="3"
                    cols="6"
                >
                    <p>
                        <b>WiFiManager:</b>
                            <br />
                            <br />
                        {{ $t(`sensorPage.library`) }}
                        </p>
                    </v-col>
                    <v-col
                        lg="6"
                        md="3"
                        cols="6"
                        class="d-flex justify-end"
                    >
                        <v-btn 
                            color="primary"
                            href="https://api.littlegreenfarmers.com//storage/WiFiManager-master.zip"   
                        >
                            {{ $t(`sensorPage.download`) }}
                        </v-btn>
                    </v-col>
                </v-card-actions>
                <v-card-actions v-if="card1">
                <v-col
                    lg="6"
                    md="3"
                    cols="6"
                >
                        <p>
                            <b>3D Printing:</b>
                            <br />
                            <br />
                            {{ $t(`sensorPage.printing`) }}
                        </p>
                    </v-col>
                    <v-col
                        lg="6"
                        md="3"
                        cols="6"
                        class="d-flex justify-end"
                    >
                        <v-btn
                            color="primary"
                            href="https://api.littlegreenfarmers.com//storage/Soil-Sensor-and-Wemos-D1-Mini-Housing.zip"   
                        >
                            {{ $t(`sensorPage.download`) }}
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col
            lg="6"
            md="6"
            cols="12"
        >
            <v-card class="rounded-xl px-3">
                <v-card-title 
                class="d-flex flex-column flex-lg-row"
                @click="card2 = !card2">
                    {{ $t(`project.sensors.defaultSensor`) }}
                    <b class="pl-1">
                        ΙοΤ LoRa
                    </b>
                </v-card-title>
                <v-card-actions v-if="card2">
                    <v-col
                        lg="6"
                        md="3"
                        cols="6"
                    >
                        <p>
                            <b>{{ $t(`sensorPage.instructions`) }}</b>
                            <br />
                            <br />
                            {{ $t(`sensorPage.instructionsInfo`) }}
                        </p>
                    </v-col>
                    <v-col
                        lg="6"
                        md="3"
                        cols="6"
                        class="d-flex justify-end"
                    >
                        <v-btn
                        color="primary"
                            href="https://api.littlegreenfarmers.com//storage/%CE%91%CE%B9%CF%83%CE%B8%CE%B7%CF%84%CE%AE%CF%81%CE%B1%CF%82_%CE%99%CE%BF%CE%A4_LoRa.pdf"
                            target="_blank"
                        >
                            {{ $t(`sensorPage.download`) }}
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { apiStatus } from "@/api/constants/apiStatus.js";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus.js";
import { getAllProjects } from "@/api/projectsApi.js";
import { CONFIG } from "@/common/config.js";

import ProjectInfoCard from "@/components/InfoCard/ProjectInfoCard.vue";
import ViewProjectBtn from "@/components/InfoCard/ViewProjectBtn.vue";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
export default {
    name: "Garden",

    components: {
        ProjectInfoCard,
        ViewProjectBtn,
        LoadingSpinner,
    },

    data() {
        return {
            projects: [],
            cardHeight: CONFIG.min_card_heigth,
            mediaHeight: CONFIG.min_media_height,
            getSensorStatus: apiStatus.Idle,
            card1: false,
            card2: false,
        };
    },

    computed: {
        ...apiStatusComputed(["getSensorStatus"]),
        isInfoPage() {
            if (this.$route.params.id) {
                return true;
            }
            return false;
        },
    },

    methods: {
        async handleProjects() {
            this.getSensorStatus = apiStatus.Pending;
            const { response, error } = await withAsync(getAllProjects);

            if (error) {
                this.getSensorStatus = apiStatus.Error;
                return;
            }

            this.projects = response.data.data.filter(
                (item) => item.status == "publish" && item.category == "sensors"
            );
            this.getSensorStatus = apiStatus.Success;
        },
        // viewProject(project) {
        //     this.$router.push({
        //         name: "SensorInfo",
        //         params: { id: project.id },
        //     });
        // },
    },

    created() {
        this.handleProjects();
    },
};
</script>

<style lang="scss" scoped></style>
