<template>
    <v-row class="mt-2">
        <v-col>
            <v-row v-if="getNutritionStatus_Success">
                <v-col cols="12">
                    <v-tabs
                        v-model="tab"
                        background-color="appBackground"
                        color="nutrition"
                    >
                        <v-tab v-for="item in tabItems" :key="item.id">
                            {{ $t(`Nutrition.tabs.${item.title}`) }}
                        </v-tab>

                        <v-tab-item class="appBackground">
                            <v-row>
                                <v-col
                                    v-for="(recipe, index) in recipes"
                                    :key="index"
                                    cols="12"
                                    md="6"
                                    lg="4"
                                    xl="3"
                                    class="mt-6"
                                >
                                    <project-info-card
                                        :project="{
                                            ...recipe,
                                            category: 'nutrition',
                                        }"
                                        type="recipe"
                                        card-width="372"
                                        :card-heigth="cardHeight"
                                        :media-height="mediaHeight"
                                        media-width="372"
                                        :thumbnail="
                                            recipe.youtube_url ? true : false
                                        "
                                        @card-click="viewRecipe(recipe)"
                                    >
                                        <template #viewAction>
                                            <view-project-btn
                                                @view-project="
                                                    viewRecipe(recipe)
                                                "
                                            ></view-project-btn>
                                        </template>
                                    </project-info-card>
                                </v-col>
                                <v-col v-if="recipes.length <= 0" cols="12">
                                    Δεν υπάρχουν διαθέσιμες συνταγές
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item class="appBackground">
                            <v-row>
                                <v-col
                                    v-for="(advice, index) in advices"
                                    :key="index"
                                    cols="12"
                                    md="6"
                                    lg="4"
                                    xl="3"
                                    class="mt-6"
                                >
                                    <project-info-card
                                        :project="{
                                            ...advice,
                                            category: 'nutrition',
                                        }"
                                        type="advice"
                                        card-width="372"
                                        :card-heigth="cardHeight"
                                        :media-height="mediaHeight"
                                        media-width="372"
                                        :thumbnail="
                                            advice.youtube_url ? true : false
                                        "
                                        @card-click="viewAdvice(advice)"
                                    >
                                        <template #viewAction>
                                            <view-project-btn
                                                @view-project="
                                                    viewAdvice(advice)
                                                "
                                            ></view-project-btn>
                                        </template>
                                    </project-info-card>
                                </v-col>
                                <v-col v-if="recipes.length <= 0" cols="12">
                                    Δεν υπάρχουν διαθέσιμες συμβουλές
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="getNutritionStatus_Error" cols="12">
                    Κάτι πήγε στραβά
                </v-col>
                <loading-spinner
                    :show="getNutritionStatus_Pending"
                    color="nutrition"
                ></loading-spinner>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "@/helpers/withAsync.js";
import { getAllRecipes, getAllAdvices } from "@/api/nutritionApi.js";
import { CONFIG } from "@/common/config.js";
import { apiStatus } from "@/api/constants/apiStatus.js";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus.js";

import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import ProjectInfoCard from "@/components/InfoCard/ProjectInfoCard.vue";
import ViewProjectBtn from "@/components/InfoCard/ViewProjectBtn.vue";
export default {
    name: "Nutrition",

    components: {
        ProjectInfoCard,
        ViewProjectBtn,
        LoadingSpinner,
    },

    data() {
        return {
            tab: null,
            tabItems: [
                {
                    id: 1,
                    title: "recipes",
                },
                {
                    id: 2,
                    title: "tips",
                },
            ],
            recipes: [],
            advices: [],
            cardHeight: CONFIG.min_card_heigth,
            mediaHeight: CONFIG.min_media_height,
            getNutritionStatus: apiStatus.idle,
        };
    },

    computed: {
        ...apiStatusComputed(["getNutritionStatus"]),
        isInfoPage() {
            if (this.$route.params.id) {
                return true;
            }
            return false;
        },
    },

    methods: {
        async handleRecipes() {
            this.getNutritionStatus = apiStatus.Pending;
            const { response, error } = await withAsync(getAllRecipes);

            if (error) {
                this.getNutritionStatus = apiStatus.Error;
                return;
            }

            this.recipes = response.data.data.filter(
                (item) => item.status == "publish"
            );
            this.getNutritionStatus = apiStatus.Success;
        },
        async handleAdvices() {
            this.getNutritionStatus = apiStatus.Pending;
            const { response, error } = await withAsync(getAllAdvices);

            if (error) {
                this.getNutritionStatus = apiStatus.Error;
                return;
            }

            this.advices = response.data.data.filter(
                (item) => item.status == "publish"
            );
            this.getNutritionStatus = apiStatus.Success;
        },
        viewRecipe(recipe) {
            this.$router.push({
                name: "RecipeInfo",
                params: { id: recipe.id },
            });
        },
        viewAdvice(advice) {
            this.$router.push({
                name: "AdviceInfo",
                params: { id: advice.id },
            });
        },
    },

    created() {
        this.handleRecipes();
        this.handleAdvices();
    },
};
</script>

<style lang="scss" scoped></style>
