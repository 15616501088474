<template>
    <v-fab-transition>
        <v-btn
            color="accent darken-2"
            class="new-project-btn"
            depressed
            fixed
            dark
            top
            small
            fab
            right
            @click="createNew()"
        >
            <v-icon>fa-solid fa-plus</v-icon>
        </v-btn>
    </v-fab-transition>
</template>

<script>
export default {
    name: "NewProjectMobileButton",
    methods: {
        createNew() {
            this.$router.push({ name: "NewProject" });

            // this.$router.push({ name: "Register" });
        },
    },
};
</script>

<style lang="scss" scoped>
.new-project-btn {
    z-index: 100;
    top: 16%;
}
</style>
